.profile {
    height: 100vh;
    background-color: #E7CFC8;
    display: flex;
    justify-content: space-evenly;
}

.infoProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #70AE98;
    height: 50%;
    width: 50%;
    border-radius: 10px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-weight: bold;
    box-shadow: 0px 0px 10px 5px #000000;
}

.logout {
    position: relative;
    transform: translate(-50%, -50%);
    left: 45%;
    top: 5%;
}

.avataProfile {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    margin-bottom: 3%;
}